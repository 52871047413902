/*

Template: Sofbox - Responsive Software Landing Page
Author: iqonicthemes.in
Version: 3.0

*/

/*----------------------------------------------
Index Of Script
------------------------------------------------

1.Page Loader
2.Hey WebChat
3.Tab Selection Hash
4.Back To Top
5.Hide Menu
6.Accordion
7.Header
8.Magnific Popup
9.Countdown
10.Search Style
11.Wow Animation
12.Panorama Animation
13.Fullpage Viewer
14.Owl Carousel
15.Contact From
16.Bootstrap Carousel and Animate.css

------------------------------------------------
Index Of Script
----------------------------------------------*/
import $ from 'jquery';

$(window).on('load', function () {
    /*------------------------
    Page Loader
    --------------------------*/
    jQuery("#load").fadeOut();
    jQuery("#loading").delay(0).fadeOut("slow");

    /*------------------------
    Tab Selection Hash
    --------------------------*/
    (function () {
        const hash = window.location.hash;

        if (hash) {
            const elemenToScrollDown = $(hash);

            if (elemenToScrollDown.length) {
                let tabItem = $(`a[href="${hash}"][data-toggle='tab'], a[href="${hash}"][data-toggle='pill']`);
                let scrollTop = elemenToScrollDown.offset().top;

                if (tabItem.length) {
                    tabItem.tab && tabItem.tab('show');
                    scrollTop = tabItem.offset().top - 100;
                }

                $('html, body').animate({
                    scrollTop
                }, 900, 'swing');
            }
        }
    })();

    $(this).on('hashchange', function (event) {
        if (/tab/.test(window.location.hash)) {
            event.preventDefault();
            location.reload();
        }
    });

    /*------------------------
    Masonry
    --------------------------*/
    var jQuerymsnry = jQuery('.iq-masonry-block .iq-masonry');
    if (jQuerymsnry.length) {
        var jQueryfilter = jQuery('.iq-masonry-block .isotope-filters');
        jQuerymsnry.isotope({
            percentPosition: true,
            resizable: true,
            itemSelector: '.iq-masonry-block .iq-masonry-item',
            layoutMode: 'fitRows',
            masonry: {
                gutterWidth: 0
            }
        });
        // bind filter button click
        jQueryfilter.on('click', 'button', function () {
            var filterValue = jQuery(this).attr('data-filter');
            jQuerymsnry.isotope({
                filter: filterValue
            });
        });

        jQueryfilter.each(function (i, buttonGroup) {
            var jQuerybuttonGroup = jQuery(buttonGroup);
            jQuerybuttonGroup.on('click', 'button', function () {
                jQuerybuttonGroup.find('.active').removeClass('active');
                jQuery(this).addClass('active');
            });
        });
    }
    /*------------------------
    Isotope
    --------------------------*/

    jQuery('.isotope').length > 0 && jQuery('.isotope').isotope({
        itemSelector: '.iq-grid-item',
    });

    /*------------------------------
    filter items on button click
    -------------------------------*/
    jQuery('.isotope-filters').on('click', 'button', function () {
        var filterValue = jQuery(this).attr('data-filter');
        jQuery('.isotope').isotope({
            resizable: true,
            filter: filterValue
        });
        jQuery('.isotope-filters button').removeClass('show active');
        jQuery(this).addClass('show active');
    });
});

$(document).ready(function () {
    $('#copyright-year').html(new Date().toISOString().slice(0, 4));

    /*------------------------
    Google analytics
    --------------------------*/
    function gtag() {
        dataLayer.push(arguments);
    }

    function loadGoogleAnalytics() {
        let ga = document.createElement('script');
        ga.type = ' text/javascript';
        ga.async = true;
        ga.src = 'https://www.googletagmanager.com/gtag/js?id=G-YWZ4L1W3VZ';
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ga, s);

        window.dataLayer = window.dataLayer || [];

        gtag('js', new Date());
        gtag('config', 'G-YWZ4L1W3VZ');
    }

    /*------------------------
    Cookies
    --------------------------*/
    function setCookiesPermission() {
        const cookiesPopup = `
            <div class="position-fixed w-100 py-4 text-white" id="cookies-popup"
                style="bottom: 0; left: 0; background: var(--primary-color, #07133E); z-index: 101;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-9">
                            <p class="m-0">
                                El sitio web de <a class="text-white" href="index.html">UNIPAGO</a> utiliza cookies para ayudar a analizar como los usuarios usan el portal y recopilar información que ayuda a optimizar su visita a la página web. No se utilizarán las cookies para recoger información de carácter personal. Usted puede permitir su uso o rechazarlo siempre que lo desee. Encontrará más información en nuestra
                                <a class="text-white" style="text-decoration: underline" href="politicas_cookies.html">Política de Cookies</a>.
                            </p>
                        </div>

                        <div class="col-lg-3 mt-3 mt-lg-0">
                            <button class="btn btn-block text-info" id="accept-cookies">
                                Aceptar cookies
                            </button>

                            <a class="btn btn-block bg-white text-dark mt-3" href="politicas_cookies.html">
                                Leer más
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        `;

        $(document.body).append(cookiesPopup);

        $('#accept-cookies').click(function () {
            localStorage.setItem('enableCookies', JSON.stringify(true));
            $('#cookies-popup').remove();

            loadGoogleAnalytics();
        });
    }

    if (navigator.cookieEnabled) {
        let cookiesPermission = localStorage.getItem('enableCookies');

        if (!cookiesPermission) {
            setCookiesPermission();
        } else {
            loadGoogleAnalytics();
        }

    }

    /*------------------------
    Hey WebChat
    --------------------------*/
    // let gretting = '';
    // const currentHour = new Date().getHours();

    // if (currentHour < 12) {
    //     gretting = 'Buenos días';
    // } else if (currentHour < 18) {
    //     gretting = 'Buenas tardes';
    // } else {
    //     gretting = 'Buenas noches';
    // }

    // const chatOptions = {
    //     platformId: "unipagord",
    //     header: "GABRIELA",
    //     auth: [],
    //     // Absolute uri to styling inside iframe
    //     contentCSSAbsoluteUri: 'https://dm208a3j0a9t5.cloudfront.net/css/hey-web-chat.css',
    //     ui: {
    //         placeholder: 'Escriba su mensaje',
    //     },
    //     botConfig: {
    //         initmsg: {
    //             attachment: {
    //                 type: 'template',
    //                 payload: {
    //                     'template_type': 'generic',
    //                     'image_aspect_ratio': 'square',
    //                     elements: [
    //                         {
    //                             title: `¡${gretting}!\n Gracias por contactarnos. Soy Gabriela Bot y estoy para ayudarte.\n\n¿En qué te puedo asistir?`,
    //                             subtitle: '',
    //                             'image_url': "https://dm208a3j0a9t5.cloudfront.net/static/Gabriela+60x60.png",
    //                             buttons: [
    //                                 {
    //                                     type: 'postback',
    //                                     title: 'Hola Gabriela',
    //                                     payload: { state: 'hola' }
    //                                 }
    //                             ]
    //                         }
    //                     ]
    //                 }
    //             },
    //             Hey: {
    //                 state: { name: 'bienvenida', count: 0 }
    //             },
    //             msgType: 'message'
    //         }
    //     }

    // };

    // chatHeyNow(chatOptions);

    /*------------------------
    Back To Top
    --------------------------*/
    $('#back-to-top').fadeOut();
    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 250) {
            $('#back-to-top').fadeIn(1400);
        } else {
            $('#back-to-top').fadeOut(400);
        }
    });
    // scroll body to 0px on click
    $('#top').on('click', function (event) {
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        $('top').tooltip('hide');
        return false;
    });



    /*------------------------
    Hide Menu
    --------------------------*/
    $(".navbar a").on("click", function (event) {
        $(".navbar-collapse").collapse('hide');
    });



    /*------------------------
    Accordion
    --------------------------*/
    $('.iq-accordion .iq-ad-block .ad-details').hide();
    $('.iq-accordion .iq-ad-block:first').addClass('ad-active').children().slideDown('slow');
    $('.iq-accordion .iq-ad-block').on("click", function () {
        if ($(this).children('div').is(':hidden')) {
            $('.iq-accordion .iq-ad-block').removeClass('ad-active').children('div').slideUp('slow');
            $(this).toggleClass('ad-active').children('div').slideDown('slow');
        }
    });



    /*------------------------
    Header
    --------------------------*/
    $('.navbar-nav li a').on('click', function (e) {
        var anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $(anchor.attr('href')).offset().top - 0
        }, 1500);
        e.preventDefault();
    });

    $('header').addClass('menu-sticky');

    /*------------------------
    Amazing Tab
    --------------------------*/
    $('#iq-amazing-tab').on('click', 'a', function () {
        $('#iq-amazing-tab  li a.active1').removeClass('active1');
        $(this).addClass('active1');
    });


    /*------------------------
    Img Skrollr
    --------------------------*/
    var mySkrollr = skrollr.init({
        forceHeight: false,
        easings: {
            easeOutBack: function (p, s) {
                s = 1.70158;
                p = p - 1;
                return (p * p * ((s + 1) * p + s) + 1);
            }
        },
        mobileCheck: function () {
            //hack - forces mobile version to be off
            return false;
        }
    });

    /*------------------------
    Magnific Popup
    --------------------------*/
    $('.popup-gallery').magnificPopup && $('.popup-gallery').magnificPopup({
        delegate: 'a.popup-img',
        tLoading: 'Loading image #%curr%...',
        type: 'image',
        mainClass: 'mfp-img-mobile',
        gallery: {
            navigateByImgClick: true,
            enabled: true,
            preload: [0, 1]
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
        }
    });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup && $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        type: 'iframe',
        disableOn: 700,
        mainClass: 'mfp-fade',
        preloader: false,
        removalDelay: 160,
        fixedContentPos: false
    });



    /*------------------------
    Countdown
    --------------------------*/
    $('#countdown').countdown && $('#countdown').countdown({
        date: '10/01/2019 23:59:59',
        day: 'Day',
        days: 'Days'
    });



    /*------------------------
    Progress Bar
    --------------------------*/
    $('.iq-progress-bar > span').each(function () {
        var $this = $(this);
        var width = $(this).data('percent');
        $this.css({
            'transition': 'width 2s'
        });
        setTimeout(function () {
            $this.appear(function () {
                $this.css('width', width + '%');
            });
        }, 500);
    });



    /*------------------------
    widget
    --------------------------*/
    $('.iq-widget-menu > ul > li > a').on('click', function () {
        var checkElement = $(this).next();
        $('.iq-widget-menu li').removeClass('active');
        $(this).closest('li').addClass('active');
        if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
            $(this).closest('li').removeClass('active');
            checkElement.slideUp('normal');
        }
        if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
            $('.iq-widget-menu ul ul:visible').slideUp('normal');
            checkElement.slideDown('normal');
        }
        if ($(this).closest('li').find('ul').children().length === 0) {
            return true;
        } else {
            return false;
        }
    });



    /*------------------------
    counter
    --------------------------*/
    $('.timer').countTo && $('.timer').countTo();

    /*--------------------------
    bootstrap menu index-12
    ---------------------------*/

    $(window).on('scroll', function (e) {
        if ($('#how-it-works').length && $('#great-screenshots').length) {
            if ($(this).scrollTop() >= ($('#how-it-works').offset().top - 2000)) {
                $('#great-screenshots ul li').children('a[aria-selected=true]').addClass('active');
                e.preventDefault();
            }
        }
    });

    /*------------------------
    Screenshots silder
    --------------------------*/
    var slide = $('.slider-single');
    var slideTotal = slide.length - 1;
    var slideCurrent = -1;

    function slideInitial() {
        slide.addClass('proactivede');
        setTimeout(function () {
            slideRight();
        }, 500);
    }

    function slideRight() {
        if (slideCurrent < slideTotal) {
            slideCurrent++;
        } else {
            slideCurrent = 0;
        }

        if (slideCurrent > 0) {
            var preactiveSlide = slide.eq(slideCurrent - 1);
        } else {
            var preactiveSlide = slide.eq(slideTotal);
        }
        var activeSlide = slide.eq(slideCurrent);
        if (slideCurrent < slideTotal) {
            var proactiveSlide = slide.eq(slideCurrent + 1);
        } else {
            var proactiveSlide = slide.eq(0);

        }

        slide.each(function () {
            var thisSlide = $(this);
            if (thisSlide.hasClass('preactivede')) {
                thisSlide.removeClass('preactivede preactive active proactive').addClass('proactivede');
            }
            if (thisSlide.hasClass('preactive')) {
                thisSlide.removeClass('preactive active proactive proactivede').addClass('preactivede');
            }
        });
        preactiveSlide.removeClass('preactivede active proactive proactivede').addClass('preactive');
        activeSlide.removeClass('preactivede preactive proactive proactivede').addClass('active');
        proactiveSlide.removeClass('preactivede preactive active proactivede').addClass('proactive');
    }

    function slideLeft() {
        if (slideCurrent > 0) {
            slideCurrent--;
        } else {
            slideCurrent = slideTotal;
        }

        if (slideCurrent < slideTotal) {
            var proactiveSlide = slide.eq(slideCurrent + 1);
        } else {
            var proactiveSlide = slide.eq(0);
        }
        var activeSlide = slide.eq(slideCurrent);
        if (slideCurrent > 0) {
            var preactiveSlide = slide.eq(slideCurrent - 1);
        } else {
            var preactiveSlide = slide.eq(slideTotal);
        }
        slide.each(function () {
            var thisSlide = $(this);
            if (thisSlide.hasClass('proactivede')) {
                thisSlide.removeClass('preactive active proactive proactivede').addClass('preactivede');
            }
            if (thisSlide.hasClass('proactive')) {
                thisSlide.removeClass('preactivede preactive active proactive').addClass('proactivede');
            }
        });
        preactiveSlide.removeClass('preactivede active proactive proactivede').addClass('preactive');
        activeSlide.removeClass('preactivede preactive proactive proactivede').addClass('active');
        proactiveSlide.removeClass('preactivede preactive active proactivede').addClass('proactive');
    }

    var left = $('.slider-left');
    var right = $('.slider-right');
    left.on('click', function () {
        slideLeft();
    });
    right.on('click', function () {
        slideRight();
    });
    slideInitial();


    /*------------------------
    Wow Animation
    --------------------------*/
    var wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: false,
        live: true
    });
    wow.init();


    /*------------------------
    Owl Carousel
    --------------------------*/
    $('.owl-carousel').each(function () {
        var $carousel = $(this);
        $carousel.owlCarousel({
            items: $carousel.data("items"),
            loop: $carousel.data("loop"),
            margin: $carousel.data("margin"),
            nav: $carousel.data("nav"),
            dots: $carousel.data("dots"),
            autoplay: $carousel.data("autoplay"),
            autoplayTimeout: $carousel.data("autoplay-timeout"),
            navText: ['<i class="fa fa-angle-left fa-2x"></i>', '<i class="fa fa-angle-right fa-2x"></i>'],
            responsiveClass: true,
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: $carousel.data("items-mobile-sm")
                },
                // breakpoint from 480 up
                480: {
                    items: $carousel.data("items-mobile")
                },
                // breakpoint from 786 up
                786: {
                    items: $carousel.data("items-tab")
                },
                // breakpoint from 1023 up
                1023: {
                    items: $carousel.data("items-laptop")
                },
                1199: {
                    items: $carousel.data("items")
                }
            }
        });
    });


    /*------------------------
    Contact From
    --------------------------*/
    $('#contact').submit(function (e) {
        var flag = 0;
        e.preventDefault(); // Prevent Default Submission
        $('.require').each(function () {
            if ($.trim($(this).val()) == '') {
                $(this).css("border", "1px solid red");
                e.preventDefault(); // Prevent Default Submission
                flag = 1;
            } else {
                $(this).css("border", "1px solid grey");
                flag = 0;
            }
        });

        if (grecaptcha.getResponse() == "") {
            flag = 1;
            alert('Please verify Recaptch');

        } else {
            flag = 0;
        }

        if (flag == 0) {
            $.ajax({
                url: 'php/contact-form.php',
                type: 'POST',
                data: $("#contact").serialize() // it will serialize the form data
            })
                .done(function (data) {
                    $("#result").html('Form was successfully submitted.');
                    $('#contact')[0].reset();
                })
                .fail(function () {
                    alert('Ajax Submit Failed ...');
                });
        }

    });

    /*------------------------
    Input Mask
    --------------------------*/
    $("input[data-numeric=true]").on('input', function (e) {
        $(this).val($(this).val().replace(/[^0-9]/g, ''));
    });


    $("#tab-hover .nav-link").mouseover(function () {
        $(this).tab("show");
    });

    $("#tab-hover .nav-link").on("shown.bs.tab", function (e) {
        const tabPanelId = e?.relatedTarget?.getAttribute("href");
        tabPanelId && $(tabPanelId).removeClass("active");
    });

    $("#tab-hover .nav-link").click(function (e) {
        const href = $(e?.currentTarget).attr('data-href');
        window.location.href = href;
    });

    $("#tab-hover").mouseleave(function () {
        $('#tab-hover .active').removeClass("active");
    });
});